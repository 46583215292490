<template>
  <b-modal
    v-model="isActive"
    title="APPROVED OFFERS"
    modal-class="modal-primary"
    size="xmd"
    hide-footer
    @hidden="handleHidden"
  >
    <b-table
      ref="modalTable"
      class="position-relative"
      responsive
      small
      show-empty
      sticky-header="50vh"
      :fields="fields"
      :items="tableProvider"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-1" />
          <strong>Loading ...</strong>
        </div>
      </template>
      <template #cell(balance)="data">
        <span class="no-wrap">$ {{ data.value }}</span>
      </template>
      <template #cell(result)="data">
        <b-badge
          :variant="data.value == 'ACCEPT' ? 'light-success' : 'light-danger'"
          pill
          >{{ data.value }}</b-badge
        >
      </template>
      <template #cell(request_percentage)="data">
        $ {{ data.item.offer_amount }} ({{ (((Number(data.item.offer_amount))/(Number(data.item.balance)))*100).toFixed(2) }} %)
        </template
      >
      <template #cell(offer_request)="data">
        $ {{ data.value }} ({{ ((data.item.offer_request / data.item.balance) * 100).toFixed(2) }} %)
      </template>
      <template #cell(negotiator)="data">
        {{ data.item.NEGOTIATOR }}
      </template>
      <!-- <template #cell(request_percentage)="data">
        {{ ((data.item.offer_request / data.item.balance) * 100).toFixed(2) }}
        %</template
      >
      <template #cell(offer_request)="data"> $ {{ data.value }} </template>
      <template #cell(process)="data">
        <b-badge :variant="colorizeProcess(data.item.process_num)" pill>{{
          data.value
        }}</b-badge>
      </template> -->

      <template #cell(created_at)="data">
        {{ data.value | myGlobal }}
      </template>
      <template #cell(offer_amount)="data">
        $ {{ data.value | currency }}
      </template>
      <template #cell(offer_date)="data">
        {{ data.value | myGlobal }}
      </template>
      <template #cell(offer_type)="data">
        {{ data.item.request_type }}
      </template>
      <!-- <template #cell(negotiator)="data">
        {{ data.item.NEGOTIATOR }}
      </template> -->
      <template #cell(tracking)="data">
        <feather-icon
          class="text-primary cursor-pointer"
          icon="AlignLeftIcon"
          size="22"
          @click="openTrackingModal(data.item.offer_id)"
        />
      </template>
      <template #cell(actions)="data">
        <feather-icon
          class="text-success cursor-pointer"
          icon="EyeIcon"
          size="22"
          @click="openSettlementModal(data.item)"
        />
        <b-badge v-if="data.item.status==6" variant="success" class="d-block" style="margin-top:3px">
          Negotiation <br> Completed
        </b-badge>
      </template>
    </b-table>
    <settlement-offer-modal
      v-if="showSettlementModal"
      :eventId="eventId"
      :offerInfo="settlementModalInfo"
      @closing="showSettlementModal = false"
    />
    <settlement-tracking-modal
      :show="showTrackingModal"
      :offer="trackingModalInfo"
      @closing="showTrackingModal = false"
    />
  </b-modal>
</template>

<script>
import SettlementOfferModal from "./SettlementOfferModal.vue";
import SettlementTrackingModal from "./SettlementTrackingModal.vue";

export default {
  components: {
    SettlementOfferModal,
    SettlementTrackingModal,
  },
  props: {
    show: Boolean,
    info: Object,
    eventId:null
  },
  data() {
    return {
      isActive: false,
      fields: [
        {
          key: "balance",
        },
        {
          key: "request_percentage",
          label: "AMG OFFER",
        },
        {
          key: "offer_request",
          label: "CREDITOR OFFER",
        },
        {
          key: "result",
        },
        {
          key: "process",
        },
        {
          key: "offer_date",
        },
        {
          key: "request_type",
        },
        {
          key: "negotiator",
        },
        {
          key: "tracking",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "actions",
          thClass: "text-center",
          tdClass: "text-center",
        },
      ],
      showTrackingModal: false,
      trackingModalInfo: null,
      showSettlementModal: false,
      settlementModalInfo: {},
    };
  },
  methods: {
    handleHidden() {
      this.isActive = false;
      this.$emit("closing");
    },
    async tableProvider() {
      let res = await amgApi.post("/debt-solution/get-offers-approved", {
        client_account_id: this.$route.params.idClient
          ? this.$route.params.idClient
          : this.info.client_account_id,
        creditor_id: this.info.creditorId,
      });

      return res.data;
    },
    colorizeProcess(process) {
      // REQUEST, VERIFICATION, UNDERVIEW
      switch (process) {
        case 3:
          return "light-primary";
        case 1:
          return "light-info";
        case 2:
          return "light-warning";
        default:
          return "light-secondary";
      }
    },
    openTrackingModal(id) {
      this.trackingModalInfo = id;
      this.showTrackingModal = true;
    },
    openSettlementModal(item) {
      this.settlementModalInfo.id = item.offer_id;

      this.showSettlementModal = true;
    },
  },
  watch: {
    show: function (val) {
      if (val) {
        this.isActive = true;
        this.settlementModalInfo = { ...this.info };
      }
    },
  },
};
</script>
