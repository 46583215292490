<template>
  <b-modal
    v-model="onControl"
    title="FILES"
    title-tag="h3"
    modal-class="modal-primary"
    size="xmd"
    hide-footer
    @hidden="close"
  >
    <validation-observer ref="form">
      <div>
        <validation-provider v-slot="{ errors }" name="file" rules="required">
          <div class="d-flex justify-content-between mt-2">
            <b-form-file
              v-model="fileToSave"
              :state="errors[0] && fileOnSave ? false : null"
              @input="fileFill"
            />
            <div class="ml-1">
              <b-button variant="outline-primary" @click="saveFile">
                Upload
              </b-button>
            </div>
          </div>
          <span v-if="errors[0] && fileOnSave" class="text-danger">
            File {{ errors[0] }}</span
          >
        </validation-provider>
      </div>
    </validation-observer>
    <b-table
      id="tracking-list"
      ref="trackingList"
      no-border-collapse
      class="position-relative mt-2"
      :fields="fields"
      show-empty
      no-provider-filtering
      sticky-header="50vh"
      primary-key="id"
      responsive="sm"
      :items="items"
      :busy="isBusy"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-1" />
          <strong>Loading ...</strong>
        </div>
      </template>
      <template #cell(file_name)="data">
        <a
          v-if="data.item.id == null"
          class="text-primary"
          target="_blank"
          @click="printPdfAccountAction(data.item.id_account_action,data.item.route_file)"
        >
          <amg-icon icon="CustomFileIcon" class="mr-50" size="15" /><span>{{
            data.item.file_name
          }}</span>
        </a>
        <a
          v-else-if="selectedFile.id !== data.item.id"
          class="text-primary"
          target="_blank"
          :href="data.item.route_file"
        >
          <amg-icon icon="CustomFileIcon" class="mr-50" size="15" /><span>{{
            data.item.file_name
          }}</span>
        </a>
        <span v-else>
          <b-form-group>
            <b-form-input
              v-model="selectedFile.file_name"
              class="d-inline"
              style="width: 70%"
              @keyup.enter="updateFile(selectedFile)"
            />
            <tabler-icon
              icon="CheckIcon"
              size="20"
              class="text-success ml-1 cursor-pointer"
              @click="updateFile(selectedFile)"
            />
            <tabler-icon
              icon="XIcon"
              size="20"
              class="text-danger ml-1 cursor-pointer"
              @click="selectedFile = []"
            />
          </b-form-group>
        </span>
      </template>
      <template #cell(user_name)="data">
        <span>{{ data.item.user_name }}</span>
        <br />
        <span>{{ data.item.created_at }}</span>
      </template>
      <template #cell(modified_by)="data">
        <span>{{ data.item.modified_by }}</span>
        <br />
        <span>{{ data.item.updated_at }}</span>
      </template>
      <template #cell(actions)="data">
        <feather-icon
          class="mr-1 cursor-pointer text-warning"
          icon="EditIcon"
          size="18"
          @click="selectItemToEdit(data.item)"
          v-if="!(data.item.is_settlement == 1)"
        />
        <feather-icon
          class="mr-1 cursor-pointer text-danger"
          icon="TrashIcon"
          size="18"
          @click="deleteFile(data.item.id)"
          v-if="!(data.item.is_settlement == 1)"
        />
      </template>
    </b-table>
  </b-modal>
</template>

<script>
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import { mapGetters } from "vuex";
import { windowOpen } from 'echarts/lib/util/format';

export default {
  props: {
    idcreditor: null,
    idcreditoraccount: null,
    caid: null,
  },
  data() {
    return {
      onControl: false,
      isBusy: false,
      fileToSave: [],
      showUpload: false,
      selectedFile: [],
      fields: [
        {
          key: "file_name",
          label: "Name",
        },
        {
          key: "user_name",
          label: "Upload By",
        },
        {
          key: "modified_by",
          label: "Modified By",
        },
        {
          key: "actions",
          label: "Actions",
          thStyle: {
            width: "120px",
          },
        },
      ],
      items: [],
      lastFileName: "",
      fileOnSave: false,
    };
  },
  async created() {
    this.onControl = true;
    await this.getFilesDs();
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  methods: {
    close() {
      this.$emit("close");
      this.$emit("reload");
    },
    async fileFill() {
      if (this.fileToSave !== []) {
        const reader = new FileReader();
        reader.readAsDataURL(this.fileToSave);
        reader.onload = async (file) => {
          this.fileToSave.image = file.target.result;
        };
      }
    },
    async getFilesDs() {
      try {
        this.isBusy = true;
        const data = await ClientsOptionsServices.searchFilesAccountDs({
          id: this.idcreditoraccount,
        });
        if (data.status === 200) {
          this.items = data.data;
          this.isBusy = false;
        }
      } catch (e) {
        this.isBusy = false;
        this.showErrorSwal(e);
      }
    },
    async selectItemToEdit(item) {
      this.lastFileName = item.file_name;
      this.selectedFile = item;
      const arraySplit = this.selectedFile.file_name.split(".");
      this.selectedFile.file_name = this.selectedFile.file_name.replace(
        `.${arraySplit[arraySplit.length - 1]}`,
        ""
      );
    },
    async updateFile() {
      try {
        this.addPreloader();
        const data = await ClientsOptionsServices.saveEditedFileAcc({
          id: this.selectedFile.id,
          caid: this.caid,
          idcreditoraccount: this.idcreditoraccount,
          newfile: this.selectedFile.file_name,
          extension: this.selectedFile.extension,
          iduser: this.currentUser.user_id,
          route: this.selectedFile.route,
        });
        if (data.status === 200) {
          this.removePreloader();
          this.selectedFile = [];
          await this.getFilesDs();
          this.removePreloader();
          this.showToast(
            "success",
            "top-right",
            "Congratulations",
            "CheckIcon",
            "Renamed successfully"
          );
        }
      } catch (e) {
        this.removePreloader();
        this.showErrorSwal(e);
      }
    },
    async saveFile() {
      try {
        this.fileOnSave = true;
        const result = await this.$refs.form.validate();
        if (result) {
          this.addPreloader();
          const data = await ClientsOptionsServices.uploadFilesCreditorsAccount(
            {
              idcreditor: this.idcreditor,
              idcreditoraccount: this.idcreditoraccount,
              caid: this.caid,
              file_name: this.fileToSave.name,
              file_size: this.fileToSave.size,
              file: this.fileToSave.image,
              iduser: this.currentUser.user_id,
            }
          );
          if (data.status === 200) {
            this.removePreloader();
            this.fileToSave = [];
            this.fileOnSave = false;
            await this.getFilesDs();
            this.showSuccessSwal();
          }
        }
      } catch (e) {
        this.removePreloader();
        this.showErrorSwal(e);
      }
    },
    async deleteFile(id) {
      try {
        this.addPreloader();
        this.fileOnSave = true;
        const data = await ClientsOptionsServices.deleteFilesCreditorsDs({
          id,
          iduser: this.currentUser.user_id,
        });
        if (data.status === 200) {
          this.removePreloader();
          await this.getFilesDs();
          this.showSuccessSwal();
        }
      } catch (e) {
        this.removePreloader();
        this.showErrorSwal(e);
      }
    },
    async printPdfAccountAction(id,route_file) {
      if(id){
      this.addPreloader();
      try {
        const data = await ClientsOptionsServices.printPdfAccountAction({
          id,
        });
        if (data.status === 200) {
          await this.forceFileDownloadPdf(data.data);
        }
        this.removePreloader();
      } catch (e) {
        this.removePreloader();
        this.showErrorSwal(e);
      }
      }else{
        windowOpen(route_file, '_blank');
      }
    },
  },
};
</script>

<style scoped>
</style>
