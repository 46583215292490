<template>
  <b-modal
    v-model="onControl"
    title="TRACKING"
    title-tag="h3"
    modal-class="modal-primary"
    size="lg"
    hide-footer
    @hidden="close"
  >
    <b-table
      id="tracking-list"
      ref="trackingList"
      no-border-collapse
      class="position-relative"
      :fields="fields"
      show-empty
      no-provider-filtering
      sticky-header="50vh"
      primary-key="id"
      responsive="sm"
      :items="trackingData"
      :busy="isBusy"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-1" />
          <strong>Loading ...</strong>
        </div>
      </template>
      <template #cell(created_at)="data">
        <span>{{ data.item.created_at | myGlobalDay }}</span>
      </template>
    </b-table>
  </b-modal>
</template>

<script>
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";

export default {
  props: {
    idCreditor: null,
  },
  data() {
    return {

      fields: [
        {
          key: 'action',
          label: 'Action',
        },
        {
          key: 'before',
          label: 'Before',
        },
        {
          key: 'after',
          label: 'After',
        },
        {
          key: 'user_name',
          label: 'Done by',
        },
        {
          key: 'created_at',
          label: 'Date/Time',
        },
      ],
      trackingData: [],
      isBusy: false,
    }
  },
  async created() {
    this.onControl = true
    await this.getTrackingData()
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async getTrackingData() {
      try {
        this.isBusy = true
        const data = await ClientsOptionsServices.getTrackingCreditorDs({ idcreditor: this.idCreditor })
        if (data.status === 200) {
          this.trackingData = data.data
          this.isBusy = false
        }
      } catch (e) {
        this.isBusy = false
        this.showErrorSwal(e)
      }
    },
  },
}
</script>

<style scoped>

</style>
