<template>
  <div>
    <div class="d-flex justify-content-between mb-2 mx-1">
      <div />
      <div v-if="moduleId == 5">
        <b-button
          style="margin: 0.5rem 0.5rem"
          @click="refreshCreditorsAccount"
        >
          <feather-icon icon="RefreshCcwIcon" class="mr-1" />Refresh Creditors
          Account Status</b-button
        >
        <b-button
          variant="success"
          style="margin: 0.5rem 0.5rem"
          @click="openAddCreditorModal"
        >
          <feather-icon icon="PlusIcon" class="mr-1" />CREDITOR
        </b-button>
        <b-button
          variant="primary"
          style="margin: 0.5rem 0.5rem"
          @click="exportPdf"
        >
          <amg-icon icon="FilePdfIcon" class="mr-1" />EXPORT TO PDF
        </b-button>
      </div>
    </div>
    <b-table
      responsive
      ref="accTable"
      :fields="fields"
      :items="creditors"
      :busy="isBusy"
      no-border-collapse
      class="position-relative font-small-3 table-new-customization"
      show-empty
      sticky-header="50vh"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-1" />
          <strong>Loading ...</strong>
        </div>
      </template>

      <template #cell(creditor_name)="data">
        <!-- <span
          v-if="
            data.item.name_parent != null &&
            data.item.creditor_name != data.item.name_parent
          "
          :class="[textLink, isResponsibleAccount ? '' : 'cursor-pointer']"
          @click="isResponsibleAccount ? '' : editCreditorAccount(data.item.id)"
        >
          {{ data.item.name_parent }}</span
        > -->
        <div class="d-flex align-items-stretch h-100">
          <template v-if="data.item.editCreditorName">
            <span
              :class="[textLink, isResponsibleAccount ? '' : 'cursor-pointer']"
              @click="
                isResponsibleAccount ? '' : editCreditorAccount(data.item.id)
              "
              class="d-inline-block text-truncate my-auto"
              style="max-width: 200px; width: 180px"
              >{{ creditorName(data.item) }}</span
            >
            <feather-icon
              v-if="!!data.item.validate_creditor"
              icon="Edit2Icon"
              size="18"
              class="text-primary cursor-pointer my-auto"
              @click="editCreditorName(data.item)"
            />
          </template>
          <template v-else>
            <div class="border-button-left d-flex">
              <feather-icon
                icon="XIcon"
                size="18"
                class="text-danger cursor-pointer my-auto"
                @click="cancelCreditorName(data.item)"
              />
            </div>
            <div>
              <validation-observer ref="creditorName">
                <validation-provider v-slot="{ errors }" rules="customRequired">
                  <vue-autosuggest
                    ref="autocomplete"
                    id="creditor"
                    v-model="data.item.creditor_name"
                    :suggestions="filteredOptions"
                    :get-suggestion-value="getSuggestionValue"
                    :input-props="{
                      id: 'autosuggest__input',
                      class: ['form-control ', errors[0]],
                      placeholder: 'Select',
                    }"
                    @input="onInputChange"
                    @selected="selectHandler(data.item, $event)"
                    :state="true"
                  >
                    <template slot-scope="{ suggestion }">
                      <span class="my-suggestion-item">{{
                        suggestion.item.value
                      }}</span>
                    </template>
                  </vue-autosuggest>
                </validation-provider>
              </validation-observer>
            </div>

            <div class="border-button-right d-flex">
              <feather-icon
                icon="SaveIcon"
                size="18"
                class="text-primary cursor-pointer my-auto"
                @click="saveCreditorName(data.item)"
              />
            </div>
          </template>
        </div>
        <div>
          <span
            class="text-truncate"
            style="max-width: 120px"
            v-b-tooltip.hover
            :title="data.item.account"
          >
            #. {{ data.item.account }}
          </span>
        </div>
        <div
          v-if="data.item.selected_negotiate"
          class="d-flex"
          style="gap: 10px; margin-top: 5px"
        >
          <b-badge variant="light-primary">ANALYZED</b-badge>
        </div>
        <div>
          <b-badge v-if="data.item.in_court > 0" variant="info"
            >IN COURT</b-badge
          >
        </div>
      </template>
      <template #cell(collection_ds)="data">
        <div class="d-flex justify-content-center">
          <b-form-checkbox
            v-model="data.item.collection_ds"
            disabled
            value="1"
            unchecked-value="0"
          />
        </div>
      </template>
      <template #cell(id_credits_account)="data">
        <div class="d-flex justify-content-center">
          <feather-icon
            icon="FolderIcon"
            :class="[
              data.item.cc_files > 0 ? 'text-warning' : '',
              isResponsibleAccount ? '' : 'cursor-pointer',
            ]"
            size="18"
            @click="isResponsibleAccount ? '' : openModalFiles(data.item)"
          />
        </div>
      </template>
      <template #cell(poa)="data">
        <div class="d-flex justify-content-center">
          <b-badge v-if="data.item.poa == 1" variant="light-success"
            >YES</b-badge
          >
          <b-badge v-else-if="data.item.poa == 0" variant="light-danger"
            >NO</b-badge
          >
          <span v-else> - </span>
        </div>
      </template>
      <template #cell(tracking)="data">
        <div>
          <feather-icon
            icon="ListIcon"
            class="text-primary mr-1 cursor-pointer"
            size="18"
            @click="openModalTracking(data.item.id)"
          />
        </div>
      </template>
      <template #cell(actions)="data">
        <div class="w-100 d-flex justify-content-center">
          <!-- if offer is process does not delete -->
          <!-- <feather-icon
            v-if="data.item.created_at > dateOfDsMigration && data.item.offer_status != 3" -->
          <feather-icon
            v-if="
              data.item.created_at > dateOfDsMigration &&
              data.item.account_phase_id != 7
            "
            class="text-danger"
            :class="isResponsibleAccount ? '' : 'cursor-pointer'"
            icon="TrashIcon"
            size="18"
            @click="
              isResponsibleAccount
                ? ''
                : deleteCreditorAccount(data.item.id, data.item.event_id)
            "
          />
        </div>
      </template>
      <template #cell(offerActions)="data">
        <div class="w-100 d-flex justify-content-center" style="gap: 1rem">
          <feather-icon
            class="text-info cursor-pointer"
            icon="RepeatIcon"
            title="Migrate Offer"
            size="22"
            @click="openSettlementModal('create', data.item, true)"
            v-if="
              (isSupervisor || isCeo) &&
              data.item.offer_status != 1 &&
              data.item.account_phase_id != 7
            "
          />
          <!--          if the creditor(ds_list_credit) is in ready to settle (3), late payment (1) or charge off (2), it can be created -->
          <feather-icon
            v-if="
              ([2, 3].includes(data.item.offer_status) ||
                data.item.offer_status === null) &&
              data.item.account_phase_id !== 8 &&
              data.item.account_phase_id !== 7
            "
            class="text-primary cursor-pointer"
            icon="PlusIcon"
            size="22"
            @click="openSettlementModal('create', data.item)"
          />
          <feather-icon
            v-if="
              data.item.offer_status === 1 &&
              data.item.offer_id != null &&
              data.item.result == 'PENDING' &&
              data.item.user_id == currentUser.user_id
            "
            class="cursor-pointer"
            :icon="data.item.result == 'PENDING' ? 'EditIcon' : 'EyeIcon'"
            size="22"
            :class="
              data.item.result == 'PENDING' ? 'text-success' : 'text-warning'
            "
            @click="
              openSettlementModal(
                data.item.result == 'PENDING' ? 'pending' : 'watch',
                data.item
              )
            "
          />
          <feather-icon
            v-if="
              data.item.offer_status === 1 &&
              data.item.offer_id != null &&
              data.item.result != 'PENDING'
            "
            class="cursor-pointer"
            :icon="data.item.result == 'PENDING' ? 'EditIcon' : 'EyeIcon'"
            size="22"
            :class="
              data.item.result == 'PENDING' ? 'text-success' : 'text-warning'
            "
            @click="
              openSettlementModal(
                data.item.result == 'PENDING' ? 'pending' : 'watch',
                data.item
              )
            "
          />
          <div
            class="pendingCounter cursor-pointer"
            v-if="data.item.result == 'PENDING'"
            @click="openSettlementModal('pending', data.item)"
          >
            1
          </div>
          <div
            class="rejectedCounter cursor-pointer"
            @click="openRejectedOffersModal(data.item)"
            v-if="data.item.counter_rejected > 0"
          >
            {{ data.item.counter_rejected }}
          </div>
          <div
            class="aceptedCounter cursor-pointer"
            @click="openApprovedOffersModal(data.item)"
            v-if="data.item.counter_approved > 0"
          >
            {{ data.item.counter_approved }}
          </div>
        </div>
      </template>
      <template #custom-foot>
        <b-tr>
          <b-td>
            <span class="font-small-4">Total:</span>
          </b-td>
          <b-td>
            <b-badge
              variant="light"
              :class="isDarkSkin ? 'text-black' : 'text-dark'"
            >
              <money
                class="font-small-3"
                :value="totalBalance"
                v-bind="{
                  decimal: '.',
                  thousands: ',',
                  prefix: '$ ',
                  precision: 2,
                  masked: false,
                }"
                style="border: none"
                disabled
              />
            </b-badge>
          </b-td>
          <b-td>
            <b-badge
              variant="light"
              :class="isDarkSkin ? 'text-black' : 'text-dark'"
            >
              <money
                class="font-small-3"
                :value="actualBalance"
                v-bind="{
                  decimal: '.',
                  thousands: ',',
                  prefix: '$ ',
                  precision: 2,
                  masked: false,
                }"
                style="border: none"
                disabled
              />
            </b-badge>
          </b-td>
        </b-tr>
      </template>
    </b-table>
    <add-creditor-ds-modal
      v-if="addCreditorModalController"
      :id-creditor-to-pass="idCreditorToPass"
      :state-modal="stateModal"
      @reload="getCreditors"
      @close="addCreditorModalController = false"
      @reloadAmountSaved="reloadAmountSaved"
    />
    <tracking-creditor-modal
      v-if="trackingCreditorModalController"
      :id-creditor="idCreditorToPass"
      @close="trackingCreditorModalController = false"
    />
    <upload-files-modal
      v-if="uploadFilesModalController"
      :idcreditor="accountToSend.id"
      :idcreditoraccount="accountToSend.id_credits_account"
      :caid="caid"
      @close="uploadFilesModalController = false"
      @reload="getCreditors"
    />
    <settlement-offer-modal
      :offerInfo="settlementModalInfo"
      :eventId="event_id"
      v-if="showSettlementModal"
      @closing="showSettlementModal = false"
      @reload="closeSettlementModal"
    />
    <rejected-offers-modal
      :info="settlementModalInfo"
      :eventId="event_id"
      :show="showRejectedOffersModal"
      @closing="showRejectedOffersModal = false"
    />
    <approved-offers-modal
      :info="settlementModalInfo"
      :eventId="event_id"
      :show="showApprovedOffersModal"
      @closing="showApprovedOffersModal = false"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import AddCreditorDsModal from "@/views/commons/components/clients/dashboard/options/acc-module/modals/AddCreditorDsModal.vue";
import TrackingCreditorModal from "@/views/commons/components/clients/dashboard/options/acc-module/modals/TrackingCreditorModal.vue";
import UploadFilesModal from "@/views/commons/components/clients/dashboard/options/acc-module/modals/UploadFilesModal.vue";
import SettlementOfferModal from "@/views/debt-solution/views/settlement-offer/SettlementOfferModal.vue";
import RejectedOffersModal from "@/views/debt-solution/views/settlement-offer/RejectedOffersModal.vue";
import ApprovedOffersModal from "@/views/debt-solution/views/settlement-offer/ApprovedOffersModal.vue";
import PriorityOffersService from "@/views/debt-solution/views/priority-offers/services/priorityOffersService";
import { VueAutosuggest } from "vue-autosuggest";
export default {
  components: {
    AddCreditorDsModal,
    TrackingCreditorModal,
    UploadFilesModal,
    SettlementOfferModal,
    RejectedOffersModal,
    ApprovedOffersModal,
    VueAutosuggest,
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      client: "DebtSolutionClients/G_CLIENTS",
      amountSaved: "DebtSolutionClients/G_AMOUNTSAVED",
    }),
    dateOfDsMigration() {
      return "2022-06-28"; // will be changed when the migration is done
    },
    isCeo() {
      return this.currentUser.role_id == 1;
    },
    isSupervisor() {
      return this.currentUser.role_id == 2;
    },
    isAdvisor() {
      return this.currentUser.role_id == 3;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    isResponsibleAccount() {
      if (this.$route.params.idClient) {
        return !(
          this.$store.getters["NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO"]
            .is_responsible === this.currentUser.user_id ||
          this.$store.getters["NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO"]
            .is_responsible === null
        );
      }
      return false;
    },
  },
  data() {
    return {
      trackingCreditorModalController: false,
      uploadFilesModalController: false,
      addCreditorModalController: false,
      caid: "",
      fields: [
        {
          key: "creditor_name",
          label: "Original Creditor",
          tdClass: "p-1",
        },
        {
          key: "balance",
          label: "Initial Balance",
          formatter: (val) => `$ ${val}`,
        },
        {
          key: "actual_balance",
          label: "Actual Balance",
          formatter: (val) => `$ ${val}`,
        },
        {
          key: "months_behind",
          label: "Months Behind",
        },
        {
          key: "account_status_ds",
          label: "Account Status",
        },
        {
          key: "desciption_accountphase",
          label: "Account Phase",
        },
        {
          key: "collection_ds",
          label: "Collection",
        },
        {
          key: "poa",
          label: "POA SENT",
        },
        {
          key: "id_credits_account",
          label: "Files",
        },
        {
          key: "tracking",
          label: "Tracking",
        },
        // {
        //   key: "offerActions",
        //   label: "Offer Actions",
        // },
      ],
      creditors: [],
      isBusy: false,
      stateModal: 0,
      actualBalance: 0,
      totalBalance: 0,
      idCreditorToPass: null,
      accountToSend: [],
      showSettlementModal: false,
      settlementModalInfo: {
        mode: "",
        client: "",
        creditor: "",
        creditorId: null,
        balance: null,
        percentage: null,
        id: null,
      },
      showRejectedOffersModal: false,
      showApprovedOffersModal: false,
      event_id: null,
      filteredOptions: [],
      tempEntryCreditorName: "",
      creditorsLoading: false,
      creditor_id: null,
      allValidateCreditors: false,
    };
  },
  async created() {
    this.caid = this.$route.params.idClient;
    if (this.isCeo || this.isSupervisor) {
      this.fields.push({
        key: "Actions",
        label: "actions",
      });
    }

    // For settlement offer
    if (this.moduleId == 5) {
      this.fields.push({
        key: "offerActions",
        label: "Offer Actions",
      });
    }
    await this.getCreditors();
  },
  methods: {
    creditorName(item) {
      // return item.name_parent == null || (item.name_parent == item.creditor_name) ? item.creditor_name : `(${item.creditor_name})`;
      return item.name_parent == null
        ? item.creditor_name
        : item.name_parent != item.name_children
        ? `${item.name_children} (${item.name_parent})`
        : item.name_parent;
    },
    editCreditorAccount(id) {
      this.addCreditorModalController = true;
      this.stateModal = 0;
      this.idCreditorToPass = id;
    },
    async deleteCreditorAccount(id, eventId) {
      try {
        const response = await this.showConfirmSwal();
        if (response.isConfirmed) {
          this.addPreloader();
          const data = await ClientsOptionsServices.deleteCreditsDebtSolution({
            userId: this.currentUser.user_id,
            idCredit: id,
            idAccount: this.$route.params.idClient,
            eventId,
          });
          if (data.status === 200) {
            this.removePreloader();
            this.showSuccessSwal("Account has been deleted successfully");
            await this.getCreditors();
            await this.$store.dispatch(
              "DebtSolutionClients/A_GET_FEE_AND_CHARGES",
              {
                idAccount: this.$route.params.idClient,
              }
            );
          }
        }
      } catch (e) {
        this.removePreloader();
        this.showErrorSwal(e);
      }
    },
    openModalTracking(id) {
      this.idCreditorToPass = id;
      this.trackingCreditorModalController = true;
    },
    openModalFiles(item) {
      this.uploadFilesModalController = true;
      this.accountToSend = item;
    },
    openAddCreditorModal() {
      this.addCreditorModalController = true;
      this.stateModal = 1;
      this.idCreditorToPass = null;
    },
    async refreshCreditorsAccount() {
      const data = await PriorityOffersService.updateStatusByCreditor({
        clientId: this.client.client_id,
      });
      if (data.status === 200) {
        await this.getCreditors();
      }
    },
    async getCreditors() {
      try {
        this.isBusy = true;
        const data = await ClientsOptionsServices.getCreditors({
          id: this.$route.params.idClient,
        });
        if (data.status === 200) {
          data.data.forEach((item) => {
            item.editCreditorName = true; // Por defecto, no está en modo edición
            item.originalCreditorName = item.creditor_name; // Guardar el valor original
          });
          this.creditors = data.data;
          this.allValidateCreditors = !this.creditors.some(
            (item) => item.validate_creditor == 1
          );
          if (this.creditors.length > 0) {
            this.totalBalance =
              this.creditors[0].total_balance == null
                ? 0
                : this.creditors[0].total_balance;
            this.actualBalance =
              this.creditors[0].total_actual_balance == null
                ? 0
                : this.creditors[0].total_actual_balance;
            this.event_id =
              this.creditors[0].event_id == null
                ? null
                : this.creditors[0].event_id;
          }
          await this.$store.dispatch(
            "DebtSolutionClients/A_GET_FEE_AND_CHARGES",
            {
              idAccount: this.$route.params.idClient,
            }
          );
          this.isBusy = false;
        }
      } catch (e) {
        this.isBusy = false;
        this.showErrorSwal(e);
      }
    },
    async exportPdf() {
      if (!this.allValidateCreditors) {
        this.showToast(
          "warning",
          "top-right",
          "Oops!",
          "AlertOctagonIcon",
          "You must validate all the creditors before you can export the PDF"
        );
        return;
      }
      try {
        if (this.creditors === [] || this.creditors === null) {
          this.showWarningSwal(
            "Very important!",
            "You can not generate pdfs because there are no records"
          );
        } else {
          this.addPreloader();
          const data = await ClientsOptionsServices.printFileAcc({
            id: this.creditors[0].id_sale,
          });
          if (data.status === 200) {
            this.removePreloader();
            window.open(data.data);
          }
        }
      } catch (e) {
        this.removePreloader();
        this.showErrorSwal(e);
      }
    },
    async openSettlementModal(mode, item, isMigrating = false) {
      const negotiations = await ClientsOptionsServices.getNegotiationsActive({
        creditor_id: item.id,
      });
      var confirm = null;
      if (negotiations.data[0].negotiations_active > 0) {
        confirm = await this.showConfirmSwal(
          "This creditor has negotiations in process",
          "Do you want to create an offer anyway?"
        );
      }

      if (confirm == null || confirm.isConfirmed == true) {
        const hasParent =
          item.name_parent != null && item.creditor_name != item.name_parent;
        this.settlementModalInfo = {};
        this.settlementModalInfo.mode = mode;
        this.settlementModalInfo.migrate = isMigrating;
        this.settlementModalInfo.client = this.client.client_name;
        this.settlementModalInfo.creditorName =
          item.name_parent != null && item.creditor_name != item.name_parent
            ? (this.settlementModalInfo.creditor = `${item.creditor_name} (${item.name_parent})`)
            : (this.settlementModalInfo.creditor = item.creditor_name);
        this.settlementModalInfo.creditorId = item.id;
        this.settlementModalInfo.balance = item.actual_balance;
        this.settlementModalInfo.percentage = item.min_percent;
        this.settlementModalInfo.account = this.client.id;
        this.settlementModalInfo.ssn = item.ssn;
        this.settlementModalInfo.itin = item.itin;
        this.settlementModalInfo.cpn = item.cpn;
        this.settlementModalInfo.dob = item.dob;
        this.settlementModalInfo.address = item.address;
        this.settlementModalInfo.account_number = item.account;
        if (mode == "create") {
          this.settlementModalInfo.id = null;
        } else {
          this.settlementModalInfo.id = item.offer_id;
        }

        this.settlementModalInfo.creditorShortId = item.principal_creditor_id;
        this.settlementModalInfo.creditorAgent = item.principal_creditor_agent;
        this.settlementModalInfo.creditorContact =
          item.principal_creditor_contact_number;
        this.settlementModalInfo.profitPercent = item.profit_percent;

        this.settlementModalInfo.accountStatusDsNum =
          item.account_status_ds_num;
        this.settlementModalInfo.clientAccountId = this.client.id;
        this.settlementModalInfo.creditor_id = item.principal_creditor_id;
        this.settlementModalInfo.idState = item.id_state;
        this.settlementModalInfo.id_analysis = item.id_analysis;
        this.settlementModalInfo.account_name = this.client.account;

        this.showSettlementModal = true;
      }
    },
    closeSettlementModal() {
      this.showSettlementModal = false;
      this.$store.dispatch("DebtSolutionClients/A_COUNT_DISPUTE", {
        accountId: this.$route.params.idClient,
        userId: this.currentUser.user_id,
      });

      this.getCreditors();
    },
    validateAccountStatus(status, mode) {
      if (mode == "create") {
        return status.includes("RTS");
      }
      if (mode == "watch") {
        return status.includes("OM");
      }
      return false;
    },
    openRejectedOffersModal(item) {
      this.settlementModalInfo.mode = "watch";
      this.settlementModalInfo.client = this.client.client_name;
      this.settlementModalInfo.creditorName = item.creditor_name;
      this.settlementModalInfo.creditorId = item.id;
      this.settlementModalInfo.balance = item.actual_balance;
      this.settlementModalInfo.percentage = item.min_percent;
      this.settlementModalInfo.id = item.offer_id;
      this.settlementModalInfo.creditorShortId = item.principal_creditor_id;
      this.settlementModalInfo.creditorAgent = item.principal_creditor_agent;
      this.settlementModalInfo.creditorContact =
        item.principal_creditor_contact_number;
      this.settlementModalInfo.profitPercent = item.profit_percent;
      this.settlementModalInfo.id_analysis = item.id_analysis;
      this.settlementModalInfo.account = this.client.id;
      this.settlementModalInfo.ssn = item.ssn;
      this.settlementModalInfo.itin = item.itin;
      this.settlementModalInfo.cpn = item.cpn;
      this.settlementModalInfo.dob = item.dob;
      this.settlementModalInfo.address = item.address;
      this.settlementModalInfo.account_number = item.account;
      this.showRejectedOffersModal = true;
    },
    openApprovedOffersModal(item) {
      this.settlementModalInfo.mode = "watch";
      this.settlementModalInfo.client = this.client.client_name;
      this.settlementModalInfo.creditorName = item.creditor_name;
      this.settlementModalInfo.creditorId = item.id;
      this.settlementModalInfo.balance = item.actual_balance;
      this.settlementModalInfo.percentage = item.min_percent;
      this.settlementModalInfo.id = item.offer_id;
      this.settlementModalInfo.creditorShortId = item.principal_creditor_id;
      this.settlementModalInfo.creditorAgent = item.principal_creditor_agent;
      this.settlementModalInfo.creditorContact =
        item.principal_creditor_contact_number;
      this.settlementModalInfo.profitPercent = item.profit_percent;
      this.settlementModalInfo.id_analysis = item.id_analysis;
      this.settlementModalInfo.account = this.client.id;
      this.settlementModalInfo.account_name = this.client.account;
      this.settlementModalInfo.ssn = item.ssn;
      this.settlementModalInfo.itin = item.itin;
      this.settlementModalInfo.cpn = item.cpn;
      this.settlementModalInfo.dob = item.dob;
      this.settlementModalInfo.address = item.address;
      this.settlementModalInfo.account_number = item.account;
      this.showApprovedOffersModal = true;
    },
    reloadAmountSaved() {
      this.$store.dispatch("DebtSolutionClients/A_GET_CLIENTS", {
        id: this.$route.params.idClient,
      });
    },
    editCreditorName(item) {
      item.editCreditorName = !item.editCreditorName;
    },
    async saveCreditorName(item) {
      const validation = await this.$refs.creditorName.validate();
      if (validation) {
        try {
          const result = await this.showConfirmSwal("Are you Sure?");
          if (result.value) {
            item.editCreditorName = !item.editCreditorName;
            await ClientsOptionsServices.updateCreditoName({
              id: item.creditor_acc_id,
              name: item.creditor_name,
              id_ds_credits: item.id_creditor,
              keyword: item.originalCreditorName,
              ds_list_id: item.id,
            });
            this.getCreditors();
            this.showToast(
              "success",
              "top-right",
              "Success!",
              "CheckIcon",
              "Successful operation"
            );
          }
        } catch (error) {
          this.showErrorSwal(error);
        }
      }
    },
    cancelCreditorName(item) {
      item.creditor_name = item.originalCreditorName;
      item.editCreditorName = !item.editCreditorName;
    },
    onInputChange(text) {
      this.creditorsLoading = true;
      if (text === "" || text === undefined) {
        this.filteredOptions = [];
        this.creditorsLoading = false;
        return;
      }
      amgApi
        .post(`/sales-made/debt-solution/search-creditors?q=${text}`)
        .then((response) => {
          if (response.status === 200) {
            this.filteredOptions = [{ data: [...response.data] }];
            this.creditorsLoading = false;
          }
        });
    },
    selectHandler(item, text) {
      item.creditor_name = text.item.value;
      item.id_creditor = text.item.id;
    },
    getSuggestionValue(suggestion) {
      return suggestion.item.value;
    },
  },
};
</script>

<style scoped lang="scss">
.pendingCounter {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.8rem;
  height: 1.8rem;
  color: #ffa704;
  border: 1px solid #ffa704;
  border-radius: 50%;
  font-size: 1rem;
}
.rejectedCounter {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.8rem;
  height: 1.8rem;
  background: #fc424a1f;
  color: #fc424a;
  border: 1px solid #fc424a;
  border-radius: 50%;
  font-size: 1rem;
}
.aceptedCounter {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.8rem;
  height: 1.8rem;
  background: #00c8531f;
  color: #00c853;
  border: 1px solid #00c853;
  border-radius: 50%;
  font-size: 1rem;
}
.border-button-right {
  border-top: 1px solid rgba(255, 255, 255, 0.4);
  border-right: 1px solid rgba(255, 255, 255, 0.4);
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 0 5px 5px 0;
  margin-inline: -1.5px;
  padding: 8px;
}
.border-button-left {
  border-top: 1px solid rgba(255, 255, 255, 0.4);
  border-left: 1px solid rgba(255, 255, 255, 0.4);
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 5px 0 0 5px;
  margin-inline: -1.5px;
  padding: 8px;
}
</style>
